import { Tooltip, styled, tooltipClasses } from '@mui/material';

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  
  [`& .${tooltipClasses.tooltip}`]: {
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 11,
    padding: '8px',
    maxWidth: '200px',
    borderRadius: '5px',
    fontWeight: 600,
  },
  [`& .MuiTooltip-arrow`]: {},
}));

export default CustomTooltip;
