import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';
import { API } from '../../apis';

const initialState = {
  isLoading: false,
  error: false,
  products: [],
  totalCount: 0,
};

const slice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getCatalogSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload.data;
      state.totalCount = action.payload.total_count;
    },
    updateCatalogSuccess(state, action) {
      const column = action.payload;
      state.isLoading = false;
      state.products[column.id] = column;
    },
  },
});

export const { startLoading, hasError, getCatalogSuccess } = slice.actions;

export const getProducts = (filters) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await axiosInstance.post(API.catalog_products, {
      category_ids: filters.category_ids,
      brand_ids: filters.brand_ids,
      attribute_id: filters.attribute_id,
      price: {
        min: filters.price?.min,
        max: filters.price?.max,
      },
      keyword: filters.keyword,
      order_by: filters.order_by,
    });
    dispatch(getCatalogSuccess(response.data));
  } catch (error) {
    dispatch(hasError(error.message));
  }
};

export default slice.reducer;
