import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import DashboardNavbar from './DashboardNavbar';
import { Toolbar, useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { authReduxSelector } from 'src/redux/slices/authentication';

const RootStyle = styled('div')({
  display: 'flex',
  overflow: 'hidden',
  height: '90%',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  height: '100%',
  padding: '10px 0 0',
  transition: theme.transitions.create('margin', {
    duration: theme.transitions.duration.complex,
  }),
}));

export default function DashboardLayout() {
  const theme = useTheme();
  const { user } = useSelector((state) => authReduxSelector(state));
  const { paymentDaysRemain } = useSelector((state) => state.user);
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isHome = pathname === PATH_DASHBOARD.root;
  const promocode = localStorage.getItem('promo');

  const checkUserAddToken = user?.is_user_added_token
    ? PATH_DASHBOARD.general.shop
    : PATH_DASHBOARD.general.analytics;

  const autoNavigateLink = promocode
    ? PATH_DASHBOARD.general.promocode
    : checkUserAddToken;

  useEffect(() => {
    if (isHome) {
      navigate(autoNavigateLink);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHome, user?.is_user_added_token]);

  return (
    <>
      <Toolbar />
      {paymentDaysRemain > 0 || <Toolbar />}
      <RootStyle>
        <DashboardNavbar />
        <MainStyle>
          <Outlet />
        </MainStyle>
      </RootStyle>
      {isLargeScreen || <Toolbar />}
    </>
  );
}
