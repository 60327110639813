import React from 'react';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const CustomBreadcrumbs = ({ items }) => {
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator="-"
      sx={{
        '.MuiBreadcrumbs-separator': {
          margin: '5px',
        },
      }}
    >
      {items.map((item, index) => {
        if (index === items.length - 1) {
          return (
            <Typography
              variant="body2"
              color="#616cf5"
              key={item.name}
              sx={{ textDecoration: 'underline' }}
            >
              {item.name}
            </Typography>
          );
        }
        return (
          <Link
            component={RouterLink}
            to={item.href}
            key={item.name}
            color="inherit"
          >
            <Typography p={0} variant="body2">
              {item.name}
            </Typography>
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default CustomBreadcrumbs;
