// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  ResetPasswordConfirm: path(ROOTS_AUTH, '/reset-password-confirm'),
  verify: path(ROOTS_AUTH, '/verify'),
  verifyCodeSent: path(ROOTS_AUTH, '/confirm-email-sent'),
  emailVerifyConfirm: path(ROOTS_AUTH, '/email-verify-confirm'),
};

export const PATH_PAGE = {
  terms: '/terms',
  agreement: '/agreement',
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  landing: '/landing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
  privacy: '/privacy',
  promocode: '/promocode',
  forum: '/forum',
  price: '/price',
  cards: '/cards',
  portal_specialist: '/user/cards',
  ourExtension:
    'https://chrome.google.com/webstore/detail/isistant/ccnjccoemjdajbmaompkjgmoidicnkpb?hl=ru',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
    shop: path(ROOTS_DASHBOARD, '/shop'),
    brand: path(ROOTS_DASHBOARD, '/brand'),
    seller: path(ROOTS_DASHBOARD, '/seller'),
    promocode: path(ROOTS_DASHBOARD, '/promocode'),
    portal: path(ROOTS_DASHBOARD, '/video-portal'),
    video_teaching: path(ROOTS_DASHBOARD, '/video-teaching'),
    video_instructor: path(ROOTS_DASHBOARD, '/video_instructor'),
    module: path(ROOTS_DASHBOARD, '/module-portal'),
    calculator: path(ROOTS_DASHBOARD, '/profit-calculator'),
    calculator_view: path(ROOTS_DASHBOARD, '/profit-calculator/detail/'),
    calculator_create: path(ROOTS_DASHBOARD, '/profit-calculator/create'),
    media_portal: path(ROOTS_DASHBOARD, '/media-portal'),
    keyword_list: path(ROOTS_DASHBOARD, '/keyword-list'),
    keyword_section: path(ROOTS_DASHBOARD, '/keyword-selection'),
    forum: path(ROOTS_DASHBOARD, '/forum'),
    financial_report: path(ROOTS_DASHBOARD, '/financial-report'),
    unit_economy: path(ROOTS_DASHBOARD, '/unit-economy'),
    profit_calculator: path(ROOTS_DASHBOARD, '/profit_calculator'),
    semantic_core: path(ROOTS_DASHBOARD, '/semantic-core'),
    description_generator: path(ROOTS_DASHBOARD, '/description-generator'),
    category_analytics: path(ROOTS_DASHBOARD, '/category-analytics'),
    featured_categories: path(ROOTS_DASHBOARD, '/featured-categories'),
    auto_dumping: path(ROOTS_DASHBOARD, '/auto-dumping'),
    generate_barcode: path(ROOTS_DASHBOARD, '/generate-barcode'),
    catalog: path(ROOTS_DASHBOARD, '/catalog'),
    checkout: path(ROOTS_DASHBOARD, '/checkout'),
    catalog_favorites: path(ROOTS_DASHBOARD, '/catalog_favorites'),
    catalog_orders: path(ROOTS_DASHBOARD, '/catalog_orders'),
    catalog_order_details: path(ROOTS_DASHBOARD, '/catalog_order_details'),
    my_products: path(ROOTS_DASHBOARD, '/my_products'),
    balance: path(ROOTS_DASHBOARD, '/balance'),
    create_order: path(ROOTS_DASHBOARD, '/create_order'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey'),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    billing: path(ROOTS_DASHBOARD, '/user/billing'),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
    productById: path(
      ROOTS_DASHBOARD,
      '/e-commerce/product/nike-air-force-1-ndestrukt',
    ),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    editById: path(
      ROOTS_DASHBOARD,
      '/e-commerce/product/nike-blazer-low-77-vintage/edit',
    ),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    postById: path(
      ROOTS_DASHBOARD,
      '/blog/post/apply-these-7-secret-techniques-to-improve-event',
    ),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post'),
  },
  categories: {
    root: path(ROOTS_DASHBOARD, '/featured-categories'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
