import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { setDataTestId } from 'src/utils/setDataTestId';
const CustomModal = ({
  title,
  subTitle,
  isOpen,
  onClose,
  children,
  closeBtnTitle,
  submitBtnTitle,
  onSubmit,
  closeButtonColor = 'error',
  isCloseBtnFullWidth,
  hideCloseButton,
  data_test_id,
}) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: isMobile ? 300 : 600,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 2,
        }}
      >
        <Box>
          <Typography variant="h6" component="h2">
            {title}
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="body2" color="text.secondary">
            {subTitle}
          </Typography>
        </Box>
        <Box>{children}</Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }} mt={2}>
          {!hideCloseButton && (
            <Button
              fullWidth={isCloseBtnFullWidth}
              variant="contained"
              color={closeButtonColor}
              onClick={onClose}
            >
              {closeBtnTitle ? closeBtnTitle : 'Закрыть'}
            </Button>
          )}
          {onSubmit && (
            <Button
              variant="contained"
              onClick={onSubmit}
              {...setDataTestId({ name: data_test_id })}
            >
              {submitBtnTitle ? submitBtnTitle : 'Отправить'}
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default CustomModal;
