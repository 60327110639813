import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'src/apis';
import axiosInstance from 'src/utils/axios';
import { PATH_DASHBOARD } from '../../routes/paths';

const initialState = {
  orders: [],
  currentOrder: null,
  isLoading: false,
  totalCount: 0,
  next: null,
  previous: null,
  error: null,
};

export const createOrder = createAsyncThunk(
  'orders/createOrder',
  async (orderData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(API.catalog_orders, orderData);
      localStorage.setItem('cart', JSON.stringify([]));

      if (response.data?.order_number) {
        window.location.href = `${PATH_DASHBOARD.general.catalog_order_details}/${response.data.id}`;
      } else {
        window.location.href = PATH_DASHBOARD.general.catalog_orders;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const editOrder = createAsyncThunk(
  'orders/editOrder',
  async ({ orderId, orderData }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `${API.catalog_orders}${orderId}/`,
        orderData,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchOrderDetails = createAsyncThunk(
  'orders/fetchOrderDetails',
  async (orderId, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `${API.catalog_orders}${orderId}/`,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const payOrder = createAsyncThunk(
  'orders/payOrder',
  async (orderId, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `${API.catalog_orders}${orderId}/pay/`,
      );
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue(err.response.data);
      } else {
        return rejectWithValue({ message: 'An error occurred' });
      }
    }
  },
);

export const cancelOrder = createAsyncThunk(
  'orders/cancelOrder',
  async (orderId, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(
        `${API.catalog_orders}${orderId}/cancel/`,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

// Slice
const catalogOrdersSlice = createSlice({
  name: 'catalog_orders',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setOrders(state, action) {
      state.orders = action.payload.data;
      state.totalCount = action.payload.total_count;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.isLoading = false;
    },
    setCurrentOrder(state, action) {
      state.currentOrder = action.payload;
      state.isLoading = false;
    },
    clearError(state) {
      state.error = null;
    },
    addOrder(state, action) {
      state.orders.push(action.payload);
      state.isLoading = false;
    },
    updateOrder(state, action) {
      const { id, updatedOrder } = action.payload;
      const index = state.orders.findIndex((order) => order.id === id);
      if (index !== -1) {
        state.orders[index] = updatedOrder;
      }
      state.isLoading = false;
    },
    removeOrder(state, action) {
      const id = action.payload;
      state.orders = state.orders.filter((order) => order.id !== id);
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.orders.push(action.payload);
      })
      .addCase(createOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(editOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        const index = state.orders.findIndex(
          (order) => order.id === action.payload.id,
        );
        if (index !== -1) {
          state.orders[index] = action.payload;
        }
      })
      .addCase(editOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchOrderDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchOrderDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentOrder = action.payload;
      })
      .addCase(fetchOrderDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(payOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(payOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        const index = state.orders.findIndex(
          (order) => order.id === action.payload.id,
        );
        if (index !== -1) {
          state.orders[index].paid = true;
        }
      })
      .addCase(payOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(cancelOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(cancelOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.orders = state.orders.filter(
          (order) => order.id !== action.payload.id,
        );
      })
      .addCase(cancelOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const {
  setOrders,
  addOrder,
  updateOrder,
  removeOrder,
  setCurrentOrder,
  clearError,
} = catalogOrdersSlice.actions;
export default catalogOrdersSlice.reducer;

export function getOrders(filters) {
  return async (dispatch) => {
    dispatch(catalogOrdersSlice.actions.startLoading());
    try {
      const response = await axiosInstance.get(API.catalog_orders, {
        params: filters,
      });
      dispatch(catalogOrdersSlice.actions.setOrders(response.data));
    } catch (error) {
      console.error(error);
      dispatch(catalogOrdersSlice.actions.hasError(error));
    }
  };
}
